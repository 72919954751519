export enum LocalStorageKeysEnum {
  language = 'language',
  user = 'user',
}

class LocalStorageService {
  setItem = (item: string, value: string) => {
    try {
      localStorage.setItem(item, value)
    } catch (error) {
      console.error('Error setting data in local storage:', error)
    }
  }

  getItem = (item: string): string | null => {
    try {
      return localStorage.getItem(item)
    } catch (error) {
      console.error('Error getting data from local storage:', error)

      return null
    }
  }

  removeItem = (item: string) => {
    try {
      localStorage.removeItem(item)
    } catch (error) {
      console.error('Error removing data from local storage:', error)
    }
  }
}

export const localStorageService = new LocalStorageService()
