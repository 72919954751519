export enum QueryParamsEnum {
  ID = 'id',
  MONTH = 'month',
  DEP = 'dep',
  DEST = 'dest',
}
export type QueryParams = { [key in QueryParamsEnum]: string | number | boolean }

const serializeQuery = (queryObj: QueryParams) =>
  Object.entries(queryObj)
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&')

class Locations {
  createUrl = (url: string, queryParams?: QueryParams) =>
    queryParams ? `${url}?${serializeQuery(queryParams)}` : url

  home = () => '/'

  logIn = () => '/login'

  packageDaily = (queryParams?: QueryParams) => this.createUrl('/package-daily', queryParams)

  packagePerFlight = (queryParams?: QueryParams) =>
    this.createUrl('/package-per-flight', queryParams)

  flightsMonthly = (queryParams?: QueryParams) => this.createUrl('/flights-monthly', queryParams)

  flightsDaily = (queryParams?: QueryParams) => this.createUrl('/flights-daily', queryParams)

  packageMonthly = (queryParams?: QueryParams) => this.createUrl('/package-monthly', queryParams)
}

export const locations = new Locations()
