import { Box } from '@mui/material'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Header } from 'components/molecules/Header'
import { LocalStorageKeysEnum, localStorageService } from 'utils/localStorageHelper'
import { locations } from 'utils/locations'

export const MainLayout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorageService.getItem(LocalStorageKeysEnum.user)) {
      navigate(locations.logIn())
    }
  }, [])

  return (
    <Box height="100vh">
      <Header />
      <Outlet />
    </Box>
  )
}
