import { Box, ThemeProvider, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { LanguageContextProvider } from 'components/LanguageContextProvider'
import { Router } from 'routes'
import './App.css'
import { store } from 'store/store'

function App() {
  const theme = useTheme()

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <LanguageContextProvider>
            <Suspense
              fallback={
                <Box
                  sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Router />
            </Suspense>
          </LanguageContextProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
