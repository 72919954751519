import { combineReducers } from 'redux'
import { dashboardReducer } from './dashboard/dashboard.reducer'
import { flightDailyReducer } from './flightDaily/flightDaily.reduser'
import { flightMonthlyReducer } from './flightMonthly/flightMonthly.reduser'
import { storeLanguageReducer } from './language/language.reducer'
import { packageDailyReducer } from './packageDaily/packageDaily.reducer'
import { packageMonthlyReducer } from './packageMonthly/packageMonthly.reducer'
import { packagesPerFlightReducer } from './packagesPerFlight/packagesPerFlight.reducer'

export const rootReducer = combineReducers({
  language: storeLanguageReducer,
  packageDaily: packageDailyReducer,
  packagesPerFlight: packagesPerFlightReducer,
  flightMonthly: flightMonthlyReducer,
  flightDaily: flightDailyReducer,
  dashboard: dashboardReducer,
  packageMonthly: packageMonthlyReducer,
})
