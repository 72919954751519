import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { LoadingStatusEnum } from 'types/Loading'
import { IPackagesPerFlight } from 'types/PackagesPerFlight'

export const packagesPerFlightState: IPackagesPerFlight = {
  packagesPerFlight: null,
  loadingStatus: LoadingStatusEnum.INITIAL,
}

export function packagesPerFlightReducer(
  state = packagesPerFlightState,
  action: AnyAction,
): IPackagesPerFlight {
  switch (action.type) {
    case ActionTypeEnum.SET_PACKAGES_PER_FLIGHT: {
      return { ...state, packagesPerFlight: action.payload }
    }
    case ActionTypeEnum.SET_PACKAGES_PER_FLIGHT_LOADING_STATUS: {
      return { ...state, loadingStatus: action.payload }
    }
    default:
      return state
  }
}
