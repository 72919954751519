import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { IFlightMonthlyState } from 'types/Flights'
import { LoadingStatusEnum } from 'types/Loading'

export const flightMonthlyState: IFlightMonthlyState = {
  flightMonthly: [],
  loadingStatus: LoadingStatusEnum.INITIAL,
}

export function flightMonthlyReducer(
  state = flightMonthlyState,
  action: AnyAction,
): IFlightMonthlyState {
  switch (action.type) {
    case ActionTypeEnum.SET_FLIGHT_MONTHLY: {
      return { ...state, flightMonthly: action.payload }
    }
    case ActionTypeEnum.SET_FLIGHT_MONTHLY_LOADING_STATUS: {
      return { ...state, loadingStatus: action.payload }
    }
    default:
      return state
  }
}
