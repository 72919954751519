import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface IHeaderLinkProps {
  url: string
  text: string
  isActive?: boolean
}

export const HeaderLink = ({ text, isActive = false, url }: IHeaderLinkProps) => {
  const navigate = useNavigate()

  const navigationHandler = () => {
    navigate(url)
  }

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        px: '8px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        border: null,
        borderBottomColor: 'primary.main',
        cursor: 'pointer',
        color: isActive ? 'primary.main' : null,
        borderBottom: isActive ? 2 : null,

        '&:hover': {
          borderBottom: 2,
          borderBottomColor: 'primary.main',
        },
      }}
    >
      <div onClick={navigationHandler}>{text.toUpperCase()}</div>
    </Box>
  )
}
