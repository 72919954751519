import { createContext, ChangeEvent, memo, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'hooks/useAppDispatch'
import German from 'i18n/locales/de.json'
import English from 'i18n/locales/en.json'
import { setLanguageAction } from 'store/language/language.actions'
import { selectLanguageCode } from 'store/language/language.selectors'
import { SupportedLanguagesEnum } from 'types/Language'
import { LocalStorageKeysEnum, localStorageService } from 'utils/localStorageHelper'

interface ILocaleContext {
  locale: string
  selectLanguage?: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const getLanguageByLocale = (locale: string) => {
  switch (locale) {
    case SupportedLanguagesEnum.EN:
      return English
    case SupportedLanguagesEnum.DE:
      return German
    default:
      return German
  }
}

export const LocaleContext = createContext<ILocaleContext>({ locale: SupportedLanguagesEnum.DE })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContextProvider = (props: any) => {
  const dispatch = useAppDispatch()

  const languageCode = useSelector(selectLanguageCode)

  const selectedLanguage = localStorageService.getItem(
    LocalStorageKeysEnum.language,
  ) as SupportedLanguagesEnum | null

  useEffect(() => {
    if (!selectedLanguage) {
      localStorageService.setItem(LocalStorageKeysEnum.language, SupportedLanguagesEnum.DE)
      dispatch(setLanguageAction(SupportedLanguagesEnum.DE))
    } else {
      dispatch(setLanguageAction(selectedLanguage))
    }
  }, [])

  return (
    <IntlProvider
      messages={getLanguageByLocale(languageCode || selectedLanguage || SupportedLanguagesEnum.DE)}
      locale={languageCode || selectedLanguage || SupportedLanguagesEnum.DE}
      defaultLocale={SupportedLanguagesEnum.DE}
    >
      {props.children}
    </IntlProvider>
  )
}

export const LanguageContextProvider = memo(ContextProvider)
