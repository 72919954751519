import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { IFlightDailyState } from 'types/Flights'
import { LoadingStatusEnum } from 'types/Loading'

export const flightDailyState: IFlightDailyState = {
  flightDaily: [],
  loadingStatus: LoadingStatusEnum.INITIAL,
}

export function flightDailyReducer(state = flightDailyState, action: AnyAction): IFlightDailyState {
  switch (action.type) {
    case ActionTypeEnum.SET_FLIGHT_DAILY: {
      return { ...state, flightDaily: action.payload }
    }
    case ActionTypeEnum.SET_FLIGHT_DAILY_LOADING_STATUS: {
      return { ...state, loadingStatus: action.payload }
    }
    default:
      return state
  }
}
