import { lazy } from 'react'
import { useRoutes } from 'react-router-dom'

import { MainLayout } from 'layouts/MainLayout'
import { locations } from 'utils/locations'

const PackageDailyTable = lazy(() => import('pages/PackageDailyTable'))

const LogIn = lazy(() => import('pages/LogIn'))

const Dashboard = lazy(() => import('pages/Dashboard'))

const PackagePerFlightTable = lazy(() => import('pages/PackagePerFlightTable'))

const FlightMonthly = lazy(() => import('pages/FlightMonthly'))

const FlightDaily = lazy(() => import('pages/FlightDaily'))

const PackageMonthly = lazy(() => import('pages/PackageMonthlyTable'))

const getRoutes = () => [
  {
    path: locations.home(),
    element: <MainLayout />,
    children: [
      {
        path: locations.home(),
        element: <Dashboard />,
      },
      {
        path: locations.packageDaily(),
        element: <PackageDailyTable />,
      },
      {
        path: locations.packageMonthly(),
        element: <PackageMonthly />,
      },
      {
        path: locations.packagePerFlight(),
        element: <PackagePerFlightTable />,
      },
      {
        path: locations.flightsMonthly(),
        element: <FlightMonthly />,
      },
      {
        path: locations.flightsDaily(),
        element: <FlightDaily />,
      },
    ],
  },
  { path: locations.logIn(), element: <LogIn /> },
]

export const Router = () => {
  const routes = useRoutes(getRoutes())

  return routes
}
