import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { languageReducerState } from 'types/Language'

export const languageState: languageReducerState = null

export function storeLanguageReducer(
  state = languageState,
  action: AnyAction,
): languageReducerState {
  switch (action.type) {
    case ActionTypeEnum.SET_LANGUAGE_CODE: {
      return action.payload
    }
    default:
      return state
  }
}
