import { Avatar, Box } from '@mui/material'
import { useIntl } from 'react-intl'

import { useLocation, useNavigate } from 'react-router-dom'
import logo from 'assets/LogoPlaceholder.svg'
import { LocalStorageKeysEnum, localStorageService } from 'utils/localStorageHelper'
import { locations } from 'utils/locations'
import { HeaderLink } from './components/HeaderLink'
import { LanguageSwitcher } from './components/LanguageSwitcher'
import { messages } from './messages'

export const Header = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleLogout = () => {
    localStorageService.removeItem(LocalStorageKeysEnum.user)

    navigate(locations.logIn())
  }

  const navigateDashboard = () => {
    navigate(locations.home())
  }

  const links = [
    {
      url: locations.home(),
      text: messages.dashboardLinkTitle,
    },
    {
      url: locations.flightsMonthly(),
      text: messages.flightsLinkTitle,
    },
    {
      url: locations.packageMonthly(),
      text: messages.packagesLinkTitle,
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: '24px',
        borderBottom: 1,
        borderColor: 'text.primary',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'common.white',
        zIndex: 1,
      }}
      minHeight="48px"
    >
      <Box sx={{ display: 'flex', gap: '32px' }}>
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <img src={logo} onClick={navigateDashboard} />
        </Box>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {links.map((link, index) => (
            <HeaderLink
              url={link.url}
              text={formatMessage(link.text)}
              key={index}
              isActive={pathname === link.url}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LanguageSwitcher />
        <div onClick={handleLogout}>
          <Avatar
            sx={{
              backgroundColor: 'components.avatar.fill',
              maxHeight: '32px',
              maxWidth: '32px',
              cursor: 'pointer',
            }}
          >
            OP
          </Avatar>
        </div>
      </Box>
    </Box>
  )
}
