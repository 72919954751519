import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { PackageDailyStore } from 'types/HotelPackages'
import { LoadingStatusEnum } from 'types/Loading'

export const packageDailyState: PackageDailyStore = {
  loadingStatus: LoadingStatusEnum.INITIAL,
}

export function packageDailyReducer(
  state = packageDailyState,
  action: AnyAction,
): PackageDailyStore {
  switch (action.type) {
    case ActionTypeEnum.SET_HOTEL_PACKAGES: {
      return { ...state, ...action.payload }
    }
    case ActionTypeEnum.SET_HOTEL_PACKAGES_LOADING_STATUS: {
      return { ...state, loadingStatus: action.payload }
    }
    default:
      return state
  }
}
