import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { IDashboardState } from 'types/Dashboard'
import { LoadingStatusEnum } from 'types/Loading'

export const dashboardState: IDashboardState = {
  yieldInterventionAdvises: [],
  marketComparisonBookingShares: [],
  yieldBenefitsAndSpendings: [],
  hotelTopInspectionTasks: [],
  topPriceDeviationsPackages: [],
  hotelsWithOccupancyOver: [],
  topFlightInspectionTsasks: [],
  undercapacityRiskHotelFlights: [],
  flightWithOccupancyOver: [],
  hotelTopBookingReqDeviationComps: [],
  hotelAllotmentsTopChanges: [],
  destTopBookingReqDeviationComps: [],
  dashboardLoadingStatus: LoadingStatusEnum.INITIAL,
}

export function dashboardReducer(state = dashboardState, action: AnyAction): IDashboardState {
  switch (action.type) {
    case ActionTypeEnum.SET_YIELD_INTERVENTION_ADVISES: {
      return { ...state, yieldInterventionAdvises: action.payload }
    }
    case ActionTypeEnum.SET_MARKET_COMPARISON_BOOKING_SHARES: {
      return { ...state, marketComparisonBookingShares: action.payload }
    }
    case ActionTypeEnum.SET_YIELD_BENEFITS_AND_SPENDINGS: {
      return { ...state, yieldBenefitsAndSpendings: action.payload }
    }
    case ActionTypeEnum.SET_HOTEL_TOP_INSPECTION_TASKS: {
      return { ...state, hotelTopInspectionTasks: action.payload }
    }
    case ActionTypeEnum.SET_TOP_PRICE_DEVIATIONS_PACKAGES: {
      return { ...state, topPriceDeviationsPackages: action.payload }
    }
    case ActionTypeEnum.SET_HOTELS_WITH_OCCUPANCY_OVER: {
      return { ...state, hotelsWithOccupancyOver: action.payload }
    }
    case ActionTypeEnum.SET_TOP_FLIGHT_IMSPECTION_TASKS: {
      return { ...state, topFlightInspectionTsasks: action.payload }
    }
    case ActionTypeEnum.SET_UNDERCAPACITY_RISK_HOTEL_FLIGHTS: {
      return { ...state, undercapacityRiskHotelFlights: action.payload }
    }
    case ActionTypeEnum.SET_FLIGHT_WITH_OCCUPANCY_OVER: {
      return { ...state, flightWithOccupancyOver: action.payload }
    }
    case ActionTypeEnum.SET_HOTEL_TOP_BOOKING_REQ_DEVIATION_COMPS: {
      return { ...state, hotelTopBookingReqDeviationComps: action.payload }
    }
    case ActionTypeEnum.SET_HOTEL_ALLOTMENTS_TOP_CHANGES: {
      return { ...state, hotelAllotmentsTopChanges: action.payload }
    }
    case ActionTypeEnum.SET_DEST_TOP_BOOKING_REQ_DEVIATION_COMPS: {
      return { ...state, destTopBookingReqDeviationComps: action.payload }
    }
    case ActionTypeEnum.SET_DASHBOARD_LOADING_STATUS: {
      return { ...state, dashboardLoadingStatus: action.payload }
    }
    default:
      return state
  }
}
