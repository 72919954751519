import { Button, Menu, MenuItem } from '@mui/material'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'hooks/useAppDispatch'
import { setLanguageAction } from 'store/language/language.actions'
import { selectLanguageCode } from 'store/language/language.selectors'
import { SupportedLanguagesEnum } from 'types/Language'
import { LocalStorageKeysEnum, localStorageService } from 'utils/localStorageHelper'
import { useOutsideClickHandler } from 'utils/useOutsideClick'
import { LanguageSwitcherItem } from './components'

export const LanguageSwitcher = () => {
  const dispatch = useAppDispatch()

  const ref = useRef(null)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const selectedLanguage = useSelector(selectLanguageCode)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (code: SupportedLanguagesEnum) => () => {
    dispatch(setLanguageAction(code))
    localStorageService.setItem(LocalStorageKeysEnum.language, code)
    setAnchorEl(null)
  }

  const outsideClickHandler = () => {
    setAnchorEl(null)
  }

  useOutsideClickHandler(ref, outsideClickHandler)

  return (
    <div ref={ref}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ textTransform: 'none', color: 'text.primary', fontSize: '12px', fontWeight: 400 }}
      >
        <LanguageSwitcherItem
          code={
            selectedLanguage ||
            (localStorageService.getItem(
              LocalStorageKeysEnum.language,
            ) as SupportedLanguagesEnum | null) ||
            SupportedLanguagesEnum.DE
          }
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={handleClose(SupportedLanguagesEnum.EN)}
          sx={{ textTransform: 'none', color: 'text.primary', fontSize: '12px', fontWeight: 400 }}
        >
          <LanguageSwitcherItem code={SupportedLanguagesEnum.EN} />
        </MenuItem>
        <MenuItem
          onClick={handleClose(SupportedLanguagesEnum.DE)}
          sx={{ textTransform: 'none', color: 'text.primary', fontSize: '12px', fontWeight: 400 }}
        >
          <LanguageSwitcherItem code={SupportedLanguagesEnum.DE} />
        </MenuItem>
      </Menu>
    </div>
  )
}
