import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  dashboardLinkTitle: {
    id: 'Header.dashboardLinkTitle',
    defaultMessage: 'DASHBOARD',
  },
  flightsLinkTitle: {
    id: 'Header.flightsLinkTitle',
    defaultMessage: 'Flights',
  },
  packagesLinkTitle: {
    id: 'Header.packagesLinkTitle',
    defaultMessage: 'Packages',
  },
})
