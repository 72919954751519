import { AnyAction } from 'redux'
import { ActionTypeEnum } from 'store/Actions'
import { IPackageMonthlyState } from 'types/HotelPackages'
import { LoadingStatusEnum } from 'types/Loading'

export const packageMonthlyState: IPackageMonthlyState = {
  packageMonthly: [],
  loadingStatus: LoadingStatusEnum.INITIAL,
}

export function packageMonthlyReducer(
  state = packageMonthlyState,
  action: AnyAction,
): IPackageMonthlyState {
  switch (action.type) {
    case ActionTypeEnum.SET_PACKAGE_MONTHLY: {
      return { ...state, packageMonthly: action.payload }
    }
    case ActionTypeEnum.SET_PACKAGE_MONTHLY_LOADING_STATUS: {
      return { ...state, loadingStatus: action.payload }
    }
    default:
      return state
  }
}
