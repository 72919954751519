export enum ActionTypeEnum {
  SET_LANGUAGE_CODE = 'SET_LANGUAGE_CODE',
  SET_HOTEL_PACKAGES = 'SET_HOTEL_PACKAGES',
  SET_PACKAGES_PER_FLIGHT = 'SET_PACKAGES_PER_FLIGHT',
  SET_FLIGHT_MONTHLY = 'SET_FLIGHT_MONTHLY',
  SET_FLIGHT_DAILY = 'SET_FLIGHT_DAILY',
  SET_YIELD_INTERVENTION_ADVISES = 'SET_YIELD_INTERVENTION_ADVISES',
  SET_MARKET_COMPARISON_BOOKING_SHARES = 'SET_MARKET_COMPARISON_BOOKING_SHARES',
  SET_YIELD_BENEFITS_AND_SPENDINGS = 'SET_YIELD_BENEFITS_AND_SPENDINGS',
  SET_HOTEL_TOP_INSPECTION_TASKS = 'SET_HOTEL_TOP_INSPECTION_TASKS',
  SET_TOP_PRICE_DEVIATIONS_PACKAGES = 'SET_TOP_PRICE_DEVIATIONS_PACKAGES',
  SET_HOTELS_WITH_OCCUPANCY_OVER = 'SET_HOTELS_WITH_OCCUPANCY_OVER',
  SET_TOP_FLIGHT_IMSPECTION_TASKS = 'SET_TOP_FLIGHT_IMSPECTION_TASKS',
  SET_UNDERCAPACITY_RISK_HOTEL_FLIGHTS = 'SET_UNDERCAPACITY_RISK_HOTEL_FLIGHTS',
  SET_FLIGHT_WITH_OCCUPANCY_OVER = 'SET_FLIGHT_WITH_OCCUPANCY_OVER',
  SET_HOTEL_TOP_BOOKING_REQ_DEVIATION_COMPS = 'SET_HOTEL_TOP_BOOKING_REQ_DEVIATION_COMPS',
  SET_HOTEL_ALLOTMENTS_TOP_CHANGES = 'SET_HOTEL_ALLOTMENTS_TOP_CHANGES',
  SET_DEST_TOP_BOOKING_REQ_DEVIATION_COMPS = 'SET_DEST_TOP_BOOKING_REQ_DEVIATION_COMPS',
  SET_DASHBOARD_LOADING_STATUS = 'SET_DASHBOARD_LOADING_STATUS',
  SET_PACKAGE_MONTHLY = 'SET_PACKAGE_MONTHLY',
  SET_FLIGHT_DAILY_LOADING_STATUS = 'SET_FLIGHT_DAILY_LOADING_STATUS',
  SET_FLIGHT_MONTHLY_LOADING_STATUS = 'SET_FLIGHT_MONTHLY_LOADING_STATUS',
  SET_HOTEL_PACKAGES_LOADING_STATUS = 'SET_HOTEL_PACKAGES_LOADING_STATUS',
  SET_PACKAGE_MONTHLY_LOADING_STATUS = 'SET_PACKAGE_MONTHLY_LOADING_STATUS',
  SET_PACKAGES_PER_FLIGHT_LOADING_STATUS = 'SET_PACKAGES_PER_FLIGHT_LOADING_STATUS',
}
