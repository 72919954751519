import { AnyAction, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { IState } from 'types/States'
import { dashboardState } from './dashboard/dashboard.reducer'
import { flightDailyState } from './flightDaily/flightDaily.reduser'
import { flightMonthlyState } from './flightMonthly/flightMonthly.reduser'
import { languageState } from './language/language.reducer'
import { packageDailyState } from './packageDaily/packageDaily.reducer'
import { packageMonthlyState } from './packageMonthly/packageMonthly.reducer'
import { packagesPerFlightState } from './packagesPerFlight/packagesPerFlight.reducer'
import { rootReducer } from './rootReducers'

export const defaultState: IState = {
  language: languageState,
  packageDaily: packageDailyState,
  packagesPerFlight: packagesPerFlightState,
  flightMonthly: flightMonthlyState,
  flightDaily: flightDailyState,
  dashboard: dashboardState,
  packageMonthly: packageMonthlyState,
}

const configureStore = (state: IState) =>
  createStore(rootReducer, state, composeWithDevTools(applyMiddleware(thunk)))

export const store = configureStore(defaultState)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppDispatch = ThunkDispatch<IState, any, AnyAction>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IState, unknown, AnyAction>
