import React, { useEffect } from 'react'

export const useOutsideClickHandler = (
  ref: React.MutableRefObject<HTMLElement | null>,
  outsideClickHandler?: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        outsideClickHandler?.()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, outsideClickHandler])
}
