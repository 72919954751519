import { Box } from '@mui/material'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'

import { SupportedLanguagesEnum } from 'types/Language'

const switcherLabel = {
  [SupportedLanguagesEnum.EN]: 'ENG',
  [SupportedLanguagesEnum.DE]: 'GER',
}

interface ILanguageSwitcherItemProps {
  code: SupportedLanguagesEnum
}

export const LanguageSwitcherItem = ({ code }: ILanguageSwitcherItemProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
    <Box sx={{ borderRadius: '50%', overflow: 'hidden', width: '16px', height: '16px' }}>
      <FlagIcon code={code.toUpperCase() as FlagIconCode} size={16} />
    </Box>
    {switcherLabel[code]}
  </Box>
)
